'use client'

import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import { redirect } from 'next/navigation'

export default function ErrorPage({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) {
	function handleRedirectLogin(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		e.preventDefault()
		redirect('/logout')
	}

	function handleRefreshPage(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		e.preventDefault()
		reset()
		redirect('/')
	}

	useEffect(() => {
		console.warn('error', error)
		Sentry.captureException(error)
	}, [error])

	return (
		<div className='flex items-center justify-center min-h-screen bg-gray-100 w-full'>
			<div className='bg-white p-8 rounded-md shadow-sm text-center max-w-lg'>
				<h1 className='text-2xl font-bold text-red-600 mb-2'>Something went wrong</h1>
				<p className='text-lg text-gray-700 mb-6'>
					We're currently experiencing higher than usual traffic. Please try logging in again.
				</p>
				<div className='flex space-x-3 items-center justify-center'>
					<Link
						href='/'
						onClick={handleRefreshPage}
						className='bg-slate-50 text-slate-500 px-4 py-2.5 rounded-md hover:bg-slate-300'
					>
						Refresh Page
					</Link>
					<Link
						href='/logout'
						onClick={handleRedirectLogin}
						className='bg-slate-50 text-slate-500 px-4 py-2.5 rounded-md hover:bg-slate-300'
					>
						Sign in again
					</Link>
				</div>
			</div>
		</div>
	)
}
